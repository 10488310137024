.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bars {
    position: relative;
    width: 100px;
    height: 100px;
}

.bars div {
    position: absolute;
    left: 6px;
    width: 10px;
    background: #EFF5F5;
    animation: bars 1.5s cubic-bezier(0, 0.5, 0.5, 0.8) infinite;
}

.bars div:nth-child(1) {
    left: 6px;
    animation-delay: 0.3s;
}

.bars div:nth-child(2) {
    left: 22px;
    animation-delay: 0.6s;
}

.bars div:nth-child(3) {
    left: 38px;
    animation-delay: 0.8s;
}

@keyframes bars {
    0% {
        top: 8px;
        height: 84px;
    }

    100% {
        top: 48px;
        height: 12px;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;




* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}


@font-face {
    font-family: 'founders_grotesklight';
    src: url('foundersgrotesk-light-webfont.woff2') format('woff2'), url('foundersgrotesk-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'founders_groteskmedium';
    src: url('foundersgrotesk-medium-webfont.woff2') format('woff2'), url('foundersgrotesk-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}



body {
    font-family: "founders_grotesklight" !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.font-founders_medium {
  font-family: "founders_groteskmedium" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-inter {
  font-family: 'Inter', sans-serif;
}

._bg-gray-lighten-more {
  background-color: #f2f4f8;
}

.share-result-user-name{
    position: relative;
    top: 124px;

}

.download-image-dim{
    width: 1441px;
    height: 1025px;
}

.download-image-dim-hidden{
    display: none;
}


.mask-moniepoint {
  mask: url("./svg/Mask-Vector.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  }
  
